<template>
    <CreateBasicComponent end-point="sizes"></CreateBasicComponent>
</template>

<script>
    import CreateBasicComponent from "@/components/views/CreateBasicComponent";
    export default {
        name: 'Size',
        components: {CreateBasicComponent},
    }
</script>
